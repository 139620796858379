import classNames from 'classnames'
import Spinner from 'app/components/Spinner'

export type ButtonProps = {
  className?: string
  label?: string
  size?: `xs` | `sm` | `md` | `lg` | `xl`
  mode: `primary` | `secondary` | `white` | `danger`
  type?: `button` | `submit` | `reset`
  disabled?: boolean
  onClick?: (evt?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
  children?: React.ReactNode
  loading?: boolean
  form?: string
}

export default function Button({
  className,
  label,
  size = `md`,
  mode,
  form,
  type = `button`,
  disabled = false,
  loading = false,
  onClick,
  children,
}: ButtonProps) {
  const btnClass = classNames({
    'inline-flex items-center border rounded-full shadow-sm capitalize focus:outline-none font-medium disabled:opacity-75 disabled:pointer-events-none':
      true,
    'border-transparent': mode === `primary` || mode === `secondary`,
    'btn btn-danger': mode === `danger`,
    'border border-gray-300 bg-white text-gray-700 hover:bg-gray-50': mode === `white`,
    'px-2.5 py-1.5 text-xs': size === `xs`,
    'px-3 py-2 text-sm leading-4': size === `sm`,
    'px-4 py-2 text-sm': size === `md`,
    'px-4 py-2 text-base': size === `lg`,
    'px-6 py-3 text-base': size === `xl`,
    'bg-blue-600 text-white hover:bg-blue-600': mode === `primary`,
    'bg-trn-900 text-white hover:bg-trn-800': mode === `secondary`,
    'hover:text-white hover:bg-red-400': mode === `danger`,
    [className || ``]: className != null,
  })

  return (
    <>
      <button type={type} form={form} className={btnClass} onClick={onClick} disabled={disabled || loading}>
        {loading ? <Spinner /> : label}
        {children}
      </button>
    </>
  )
}
